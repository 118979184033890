







































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import { cs } from 'vuejs-datepicker/dist/locale';
import GoBack from '@/components/goBack.vue';
import { ArrowRightIcon, CalendarIcon } from 'vue-feather-icons';
import moment from 'moment';
import store from '@/store';
import { createVanSelectionRoute } from '@/utils/routing';
import { VanSelectionRoute } from '@/router/routes';
import { GtagMixin } from '@/mixins/googleAnalytics/gtag.mixin';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';

@Component({
  components: {
    Datepicker: () => import('vuejs-datepicker'),
    GoBack,
    ArrowRightIcon,
    CalendarIcon
  }
})
export default class VanReservationDates extends Mixins(GtagMixin) {
  $refs!: {
    dpStart: (Vue & { isOpen: boolean; close: () => void; showCalendar: () => void }) | undefined;
    dpEnd: (Vue & { isOpen: boolean; close: () => void; showCalendar: () => void }) | undefined;
  };

  /*** start dates ***/

  get startDate() {
    return store.state.reservationStartDate;
  }

  private setStartDate(date: string) {
    store.dispatch('setReservationStartDate', date);
    this.fetchData();
  }

  get startDayMonth() {
    return moment(this.startDate).locale('cs').format('D. MMMM');
  }

  get startYear() {
    return moment(this.startDate).locale('cs').format('YYYY');
  }

  private toggleStartCalendar() {
    const dpStart = this.$refs.dpStart;
    const dpEnd = this.$refs.dpEnd;
    if (dpStart) {
      if (dpStart.isOpen) {
        dpStart.close();
      } else {
        dpStart.showCalendar();
        if (dpEnd && dpEnd.isOpen) {
          dpEnd.close();
        }
      }
    }
  }

  private closeStartCalendar() {
    const dpStart = this.$refs.dpStart;
    if (dpStart && dpStart.isOpen) {
      dpStart.close();
    }
  }

  private toggleEndCalendar() {
    const dpStart = this.$refs.dpStart;
    const dpEnd = this.$refs.dpEnd;
    if (dpEnd) {
      if (dpEnd.isOpen) {
        dpEnd.close();
      } else {
        dpEnd.showCalendar();
        if (dpStart && dpStart.isOpen) {
          dpStart.close();
        }
      }
    }
  }

  private closeEndCalendar() {
    const dpEnd = this.$refs.dpEnd;
    if (dpEnd && dpEnd.isOpen) {
      dpEnd.close();
    }
  }

  get disabledDatesStart() {
    return store.state.reservationStartDisableDates;
  }

  /*** end dates ***/

  get endDate() {
    return store.state.reservationEndDate;
  }

  private setEndDate(date: string) {
    store.dispatch('setReservationEndDate', date);
  }

  get endDayMonth() {
    return moment(this.endDate).locale('cs').format('D. MMMM');
  }

  get endYear() {
    return moment(this.endDate).locale('cs').format('YYYY');
  }

  get disabledDatesEnd() {
    return store.state.reservationEndDisableDates;
  }

  get datepickerLocale() {
    return cs;
  }

  @Watch('startDate', { immediate: false })
  onChangeStartDate() {
    if (this.$route.name && this.$route.name.startsWith(VanSelectionRoute)) {
      this.$refs.dpStart?.close();
      this.$refs.dpEnd?.close();
      this.fetchData();
    }
  }

  @Watch('endDate', { immediate: false })
  onChangeEndDate() {
    if (this.$route.name && this.$route.name.startsWith(VanSelectionRoute)) {
      this.$refs.dpStart?.close();
      this.$refs.dpEnd?.close();
      this.fetchData();
    }
  }

  private async fetchData() {
    this.$nextTick(() => {
      const prevFrom = this.$route.query.from as string;
      const prevTo = this.$route.query.to as string;
      const newFrom = moment(this.startDate).format(DEFAULT_DATE_FORMAT);
      const newTo = moment(this.endDate).format(DEFAULT_DATE_FORMAT);
      if (prevFrom !== newFrom || prevTo !== newTo) {
        const params = {
          ...(store.state.cityUrl ? { secondaryParam: store.state.cityUrl } : '')
        };
        this.$store.state.carTypeUrl;
        this.$router.push({
          params,
          name: createVanSelectionRoute(),
          query: { ...this.$route.query, from: newFrom, to: newTo }
        });
      }
    });
  }
}
